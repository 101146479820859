import React from 'react';
import LongArrowRight from '../../../images/promo-svg/long-arrow-right.svg';
import images from '../../../images/homepage/index';
import * as promoBannerStyles from './style.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

import '../Homepage/style.scss';

interface PromoCard {
  image?: string;
  alt?: string;
  title?: string;
  highlight?: string;
  summary: string | { __html: string };
  buttonText: string;
  buttonUrl: string;
}

// If you build a card like this, you can't use the static image prop and loose the benefits
const PromoCard: React.FC<PromoCard> = ({ image, alt, title, highlight, summary, buttonText, buttonUrl }) => (
  <div className={promoBannerStyles.card}>
    <div className={promoBannerStyles.body}>
      {!!image && (
        <div className={promoBannerStyles.title}>
          <img src={image} alt={alt} />
        </div>
      )}
      {!!title && <div className={promoBannerStyles.title}>{title}</div>}
      {!!highlight && <div className={promoBannerStyles.highlight}>{highlight}</div>}
      <div dangerouslySetInnerHTML={summary as { __html: string }} className={promoBannerStyles.summary}></div>
      <a href={buttonUrl} className={`${promoBannerStyles.button} hoverWiggle`} target="_blank" rel="noreferrer">
        {buttonText} <img src={LongArrowRight} alt="Long Arrow Right Icon" />
      </a>
    </div>
  </div>
);

export const PromoBanner: React.FC = () => (
  <section className={`${promoBannerStyles.homePromo} d-flex container py-4 pb-4`}>
    <div className={`${promoBannerStyles.homePromoWrapper} py-6 px-4 p-lg-6`}>
      <h2 className={`mb-5 text-white text-center h4`}>What's New at the Nest</h2>
      <div className={promoBannerStyles.cardWrapper}>
        <div className={promoBannerStyles.grid}>
          <PromoCard
            title="API Discovery"
            highlight="NOW AVAILABLE!"
            summary={{
              __html:
                'Discover every API in your attack surface, get them under test quickly with automatic prioritization, and continuously oversee attack surface coverage.',
            }}
            buttonText="Learn More"
            buttonUrl="/solutions/api-discovery/"
          />
          <PromoCard
            title="Weekly AppSec Training - Every Wednesday at 10am MDT"
            summary={{
              __html:
                'Get hands-on training and improve your knowledge for using and implementing HawkScan in your development process. Every Wednesday at 10 am MST.',
            }}
            buttonText="Sign Up"
            buttonUrl="https://lp.stackhawk.com/weekly-dev-training-webinar-intro-to-hawkscan"
          />
          <PromoCard
            title="Modernizing Application Security to Scale for Cloud-native Development"
            summary={{
              __html:
                'Research from Enterprise Strategy Group highlights how IT, cybersecurity, and application development professionals are addressing  the challenges and trends to modernize application development processes.',
            }}
            buttonText="Download the eBook"
            buttonUrl="https://lp.stackhawk.com/scaling-application-security-report-download"
          />
        </div>
      </div>
    </div>
  </section>
);

export const PromoBannerHomepage: React.FC = () => (
  <section className={`${promoBannerStyles.homePromo} d-flex py-4 pb-4`}>
    <div className={`${promoBannerStyles.homePromoWrapper} p-lg-6`}>
      <h3 className={`text-white text-center`}>Explore Our Customer Stories</h3>
      <p>Learn how StackHawk customers shift left with automated API and application security testing.</p>
      <div className={promoBannerStyles.cardWrapper}>
        <div className={promoBannerStyles.grid}>
          <div className={promoBannerStyles.card}>
            <div className={promoBannerStyles.body}>
              <div className={promoBannerStyles.title}>
                <StaticImage
                  as="div"
                  src="../../../images/homepage/breathe-logo.png"
                  layout="constrained"
                  alt="BreatheLife Logo"
                />
              </div>
              <div className={promoBannerStyles.summary}>
                Breathe Life made the decision to deploy Snyk and StackHawk together so engineers could find and fix
                security vulnerabilities earlier in the development lifecycle.
              </div>
              <a
                href="/customers/breathe-life/"
                className={`${promoBannerStyles.button} hoverWiggle`}
                target="_blank"
                rel="noreferrer"
              >
                Read the Case Study <img src={LongArrowRight} alt="Long Arrow Right Icon" />
              </a>
            </div>
          </div>
          <div className={promoBannerStyles.card}>
            <div className={promoBannerStyles.body}>
              <div className={promoBannerStyles.title}>
                <StaticImage
                  as="div"
                  src="../../../images/homepage/one-medical-logo.png"
                  layout="constrained"
                  alt="OneMedical Logo"
                />
              </div>
              <div className={promoBannerStyles.summary}>
                One Medical was looking for new dynamic application and API security testing tooling to help them scale
                application security across the engineering team.
              </div>
              <a
                href="/customers/one-medical/"
                className={`${promoBannerStyles.button} hoverWiggle`}
                target="_blank"
                rel="noreferrer"
              >
                Read the Case Study <img src={LongArrowRight} alt="Long Arrow Right Icon" />
              </a>
            </div>
          </div>
          <div className={promoBannerStyles.card}>
            <div className={promoBannerStyles.body}>
              <div className={promoBannerStyles.title}>
                <StaticImage
                  as="div"
                  src="../../../images/homepage/Changeorg-logo-white.png"
                  layout="constrained"
                  alt="Change.org Logo"
                />
              </div>
              <div className={promoBannerStyles.summary}>
                Change.org needed a way to improve their security posture and effectively protect their platform and
                users at scale.
              </div>
              <a
                href="/customers/change-org/"
                className={`${promoBannerStyles.button} hoverWiggle`}
                target="_blank"
                rel="noreferrer"
              >
                Read the Case Study <img src={LongArrowRight} alt="Long Arrow Right Icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <button className="getStartedButton hoverWiggle w-100 mb-0">
        <a href="/customers/">
          View All Success Stories <img width="20px" src={images.ArrowRightIcon} alt="Right Arrow Icon" />
        </a>
      </button>
    </div>
  </section>
);
